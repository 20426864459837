import React from 'react';
import she_said_yes_pic from '../../../Assets/she-said-yes-pic.jpg';
import './SheSaidYes.css';

const OurStory: React.FC = () => {
  return (
    <div className='she-said-yes-container'>
      <div className='she-said-yes-wrapper'>
        <div className='she-said-yes-text'>
          <h2>She said yes</h2>
          <p className='bible-quote'>
            “I have found the one whom my soul loves.”
          </p>
          <p>Song of Solomon 3:4</p>
        </div>

        <div className='she-said-yes-image'>
          <img src={she_said_yes_pic} alt='She Said Yes' />
        </div>
      </div>
    </div>
  );
};

export default OurStory;
