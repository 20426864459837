import React, { useRef } from 'react';
import './GiftWindow.css';

interface GiftWindowProps {
  person_name: string;
  set_person_name: (new_person_name: string) => void;
  set_show_gift_window: (is_showing_gift_window: boolean) => void;
  set_total_person: (new_total_person: string) => void;
}

const GiftWindow: React.FC<GiftWindowProps> = props => {
  const black_bg_ref = useRef<HTMLDivElement>(null);
  const window_ref = useRef<HTMLDivElement>(null);

  const submit_rsvp = async () => {
    try {
      alert('Thank you for your response.');
      props.set_person_name('');
      props.set_total_person('1');
      close_window();
    } catch (error: any) {
      alert(error.message || error.errors[0].message || `Server error.`);
    }
  };

  const close_window = () => {
    black_bg_ref.current?.classList.replace('fade-in', 'fade-out');
    
    window_ref.current?.classList.replace(
      'slide-in-bottom',
      'slide-out-bottom'
    );
    
    setTimeout(() => props.set_show_gift_window(false), 500);
  };

  return (
    <div className='gift-window-container'>
      <div className='black-bg fade-in' ref={black_bg_ref}></div>

      <div className='close-bg'>
        <div className='window slide-in-bottom' ref={window_ref}>
          <h2>Would you like to give us a gift?</h2>

          <div className='rek-box mt-3'>
            8280370615
            <br />
            BCA a.n. Winnie Wibiren
          </div>

          <button className='btn-primary mt-3' onClick={submit_rsvp}>
            Done
          </button>

          <button className='btn-primary-outline mt-2' onClick={submit_rsvp}>
            Sorry, maybe next time
          </button>
        </div>
      </div>
    </div>
  );
};

export default GiftWindow;
