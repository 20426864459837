import React from 'react';
import './SeeYou.css';

const SeeYou: React.FC = () => {
  return (
    <div className='see-you-container'>
      <div className='see-you-wrapper'>
        <h1>See you at our wedding!</h1>
        <p>Hendra & Winnie</p>
        <p>20.02.22</p>
      </div>
    </div>
  );
};

export default SeeYou;
