import React from 'react';
import Hero from './Hero/Hero';
import './Home.css';
import OurStory from './OurStory/OurStory';
import RSVP from './RSVP/RSVP';
import Schedule from './Schedule/Schedule';
import SeeYou from './SeeYou/SeeYou';
import SheSaidYes from './SheSaidYes/SheSaidYes';

const Home: React.FC = () => {
  return (
    <>
      <Hero />
      <OurStory />
      <SheSaidYes />
      <Schedule />
      <RSVP />
      <SeeYou />
    </>
  );
};

export default Home;
