import React from 'react';
import divider from '../../../Assets/love-2.png';
import './OurStory.css';
import styles from './OurStory.module.css';

const OurStory: React.FC = () => {
  return (
    <div className='our-story-container'>
      <div className={styles.ourStoryWrapper}>
        <h2>Our Story</h2>

        <p>
          This is a story about how they met. It is a puppy love story which
          grows to be a real love story.
        </p>

        <p>
          Hendra and Winnie first met in high school, in 2009. Winnie was a new
          student in the school, had just moved from Jakarta. Their classes were
          only few steps apart.
        </p>

        <p>
          Winnie first caught Hendra&apos;s eye during reses time. He was too
          nervous to approach her at first, but one of Winnie's bestie,
          Chatrine, was a life saver. She introduced Hendra to Winnie. Later
          that day, Hendra mustered up some courage and said: “Halo, esai siopat
          bo?”, mixing up with the “ke gereja yuk,” and many jokes. Conversation
          was filled with so much laughter. Winnie thought that he was so
          unique, and the rest is history.
        </p>

        <p>
          Hendra proposed to Winnie after dating for 11 years. On that Christmas
          day, Hendra pulled out a ring before they went to the church to
          celebrate Christmas, and popped up the question. Needless to say,
          Winnie said yes! They both burst to tears, realizing what they've been
          through this far. It was the best Christmas gift ever!
        </p>

        <div className={styles.divider}>
          <img src={divider} alt='Divider' />
        </div>

        <p>
          We got a long journey, but it&apos;s been a worth journey to go
          through.
        </p>

        <p>
          We can&apos;t wait to celebrate with you, our favorite people, at our
          wedding ceremony and reception in February!
        </p>
      </div>
    </div>
  );
};

export default OurStory;
