import React from 'react';
import './Schedule.css';

const Schedule: React.FC = () => {
  return (
    <div className='schedule-container'>
      <div className='schedule-wrapper px-5 big-title'>
        <h2>Save the Date</h2>
        <p>We warmly invite you to celebrate our day</p>
      </div>

      <div className='schedule-wrapper'>
        <h3>Holy Matrimony</h3>

        <p>
          Saturday, 19 February 2022
          <br />
          10.00 AM
        </p>

        <p>
          <b>Gereja GKPB</b>
          <br />
          Jl. Pasar III Tapian Nauli No. 51
        </p>
      </div>

      <div className='schedule-wrapper'>
        <h3>Intimate Wedding</h3>

        <p>
          Sunday, 20 February 2022
          <br />
          12.00 PM
        </p>

        <p>
          <b>Aryaduta Hotel</b>
          <br />
          Grand Lotus Lt. 9
          <br />
          Jl. Kapten Maulana Lubis
        </p>
      </div>
    </div>
  );
};

export default Schedule;
