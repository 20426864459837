import React from 'react';
import './Hero.css';

const Hero: React.FC = () => {
  return (
    <div className='hero-container'>
      <div className='hero-wrapper'>
        <h1>Winnie & Hendra</h1>
      </div>
    </div>
  );
};

export default Hero;
