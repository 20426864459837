import React, { useState } from 'react';
import GiftWindow from './GiftWindow/GiftWindow';
import './RSVP.css';

const RSVP: React.FC = () => {
  const [person_name, set_person_name] = useState('');
  const [total_person, set_total_person] = useState('1');
  const [show_gift_window, set_show_gift_window] = useState(false);

  const submit_rsvp = async (attending: boolean) => {
    try {
      if (!person_name) return alert('Please input your name.');
      if (!attending) return set_show_gift_window(true);

      alert('Thank you for your response.');
      set_person_name('');
      set_total_person('1');
    } catch (error: any) {
      alert(error.message || error.errors[0].message || `Server error.`);
    }
  };

  return (
    <>
      <div className='rsvp-container'>
        <div className='rsvp-wrapper'>
          <h2 className='center-align'>Reservation</h2>

          <div className='input-group mt-3'>
            <div className='input-wrapper' style={{ flexGrow: 1 }}>
              <input
                type='text'
                placeholder='Your Name...'
                value={person_name}
                onChange={e => set_person_name(e.target.value)}
              />
            </div>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className='input-wrapper'>
                <label className='select' htmlFor={'slct'}>
                  <select
                    id='slct'
                    required
                    value={`${total_person} Guest${
                      +total_person > 1 ? 's' : ''
                    }`}
                    onChange={e => set_total_person(e.target.value)}
                  >
                    <option value='1'>1 Guest</option>
                    <option value='2'>2 Guests</option>
                  </select>

                  <svg>
                    <use xlinkHref='#select-arrow-down'></use>
                  </svg>
                </label>

                <svg className='sprites'>
                  <symbol id='select-arrow-down' viewBox='0 0 10 6'>
                    <polyline points='1 1 5 5 9 1'></polyline>
                  </symbol>
                </svg>
              </div>
            </div>
          </div>

          <div className='btn-group'>
            <button
              className='btn-primary mt-2'
              onClick={() => submit_rsvp(true)}
            >
              Accept with pleasure
            </button>

            <button
              className='btn-primary-outline mt-2'
              onClick={() => submit_rsvp(false)}
            >
              Decline with regret
            </button>
          </div>
        </div>
      </div>

      {show_gift_window ? (
        <GiftWindow
          person_name={person_name}
          set_person_name={set_person_name}
          set_show_gift_window={set_show_gift_window}
          set_total_person={set_total_person}
        />
      ) : null}
    </>
  );
};

export default RSVP;
