import './Styles/animista.css';
import './Styles/bootstrap.css';
import './Styles/reset.css';
import './Styles/root.css';

import { createRoot } from 'react-dom/client';
import App from './Pages/Home/Home';

const domNode = document.getElementById('root');
if (!domNode) throw new Error('Root element not found.');
createRoot(domNode).render(<App />);
